import React, { useState, useEffect } from 'react';
import '@dotlottie/player-component'; // Ensure this package is installed and imported

function Section4lottie({ section4Title, twitter, telegram, tiktok, instagram, section4Button, section4ContentLeft, buttonLink, twitterLink, telegramLink, tiktokLink, instaLink, logoSlogan }) {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Preload animation
    const preloadAnimation = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/animations/animation2.json`);
        if (response.ok) {
          setIsLoaded(true);
        } else {
          console.error('Failed to preload animation');
        }
      } catch (error) {
        console.error('Error preloading animation:', error);
      }
    };

    preloadAnimation();
  }, []);

  return (
    <section className="section4">
      <div className="section4-main">
        <img src={section4Title} className="section4-title" alt="Section 4 Title" />
        <div className="social-media-icons">
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={twitter} className="social-icon" alt="Twitter" />
          </a>
          <a href={telegramLink} target="_blank" rel="noopener noreferrer">
            <img src={telegram} className="social-icon" alt="Telegram" />
          </a>
          <a href={tiktokLink} target="_blank" rel="noopener noreferrer">
            <img src={tiktok} className="social-icon" alt="TikTok" />
          </a>
          <a href={instaLink} target="_blank" rel="noopener noreferrer">
            <img src={instagram} className="social-icon" alt="Instagram" />
          </a>
        </div>
        <div className="section4-content-container">
          <div className="section4-content-right-lottie">
            <a href={buttonLink} target="_blank" rel="noopener noreferrer">
              <img src={section4Button} className="section4-button" alt="section4 button" />
            </a>
            <a href={buttonLink} target="_blank" rel="noopener noreferrer">
              <img src={logoSlogan} className="section4-underbutton" alt="section4 underbutton" />
            </a>
          </div>
          <div className="section4-content-left-lottie">
          {isLoaded ? (
            <dotlottie-player
              src={`${process.env.PUBLIC_URL}/animations/animation2.json`} // Use the correct path for the hosted animation
              background="transparent"
              speed="1"
              style={{ width: 'auto', height: 'auto' }} // Set specific dimensions to test visibility
              direction="1"
              playMode="normal"
              loop
              autoplay
            />
          ) : (
            <div className="animation-placeholder">Loading...</div> // Placeholder while loading
          )}
             {/* <dotlottie-player
            src="https://lottie.host/5fb6b92a-320a-47be-8ae5-cda933e6b287/v2Lvcp3UPL.json"
            background="transparent"
            speed="1"
            style={{ width: 'auto', height: 'auto' }}
            direction="1"
            playMode="normal"
            loop
            autoplay
          /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4lottie;
