import React from 'react';

function Section2({ section2Title, section2Content }) {
  return (
    <section className="section2">
      <div className="section2-main">
        <div className="section2-title-container">
          <img src={section2Title} className="section2-title" alt="Section 2 Title" />
        </div>
        <div className="section2-content-container">
          <img src={section2Content} className="section2-content" alt="Section 2 Content" />
        </div>
      </div>
      <br></br>
    </section>
  );
}

export default Section2;
