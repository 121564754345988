// src/components/BrunosWorkshop.js
import React from 'react';
import Layers from '../components/layers';

const BrunosWorkshop = () => {
  return (
    <div>
      <h1 style={{paddingTop: '5%',}}>Put On A Bow</h1>
      <Layers />  {/* This will render the Layers component on the Services page */}
    </div>
  );
};

export default BrunosWorkshop;
