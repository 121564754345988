import React from 'react';
import config from '../config';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="placeholder-text"></p>
        <img src={config.images.footerText} className="footer-image" alt="logo" />
        <a href={config.links.board} target="_blank" rel="noopener noreferrer">
          <img src={config.images.button} className="footer-image" alt="button" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
