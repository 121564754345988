import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import logo from '../componentImages/logo.png'; // Update path to your logo image

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  if (!config.sections.showNavBar) {
    return null;
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </Link>
      </div>
      <div className="navbar-links-container">
        <ul className={`navbar-links ${mobileMenuOpen ? 'navbar-links-mobile' : ''}`}>
          <li>
            <Link to="/" className={mobileMenuOpen ? 'navbar-link-mobile' : ''} onClick={toggleMobileMenu}>Home</Link>
          </li>
          <li>
            <Link to="/WalletTool" className={mobileMenuOpen ? 'navbar-link-mobile' : ''} onClick={toggleMobileMenu}>WalletTool</Link>
          </li>
          <li>
            <Link to="/BrunosWorkshop" className={mobileMenuOpen ? 'navbar-link-mobile' : ''} onClick={toggleMobileMenu}>BrunosWorkshop</Link>
          </li>
          <li>
            <Link to="/contact" className={mobileMenuOpen ? 'navbar-link-mobile' : ''} onClick={toggleMobileMenu}>Contact</Link>
          </li>
        </ul>
        {mobileMenuOpen && (
          <div className="navbar-close" onClick={toggleMobileMenu}>
            &times; {/* Close button character */}
          </div>
        )}
      </div>
      <div className="navbar-menu" onClick={toggleMobileMenu}>
        ☰
      </div>
      <div className="navbar-social-container">
        <a href={config.links.twitter} target="_blank" rel="noopener noreferrer">
          <img src={config.images.twitter} alt="Twitter" className="navbar-social-icon" />
        </a>
        <a href={config.links.telegram} target="_blank" rel="noopener noreferrer">
          <img src={config.images.telegram} alt="Telegram" className="navbar-social-icon" />
        </a>
        <a href={config.links.tiktok} target="_blank" rel="noopener noreferrer">
          <img src={config.images.tiktok} alt="TikTok" className="navbar-social-icon" />
        </a>
        <a href={config.links.instagram} target="_blank" rel="noopener noreferrer">
          <img src={config.images.instagram} alt="TikTok" className="navbar-social-icon" />
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
