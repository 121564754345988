import React from 'react';
import '../App.css';
import Header from '../components/Header';
import Section1lottie from '../components/section1lottie';
//import Section1 from '../components/section1';
import Section1Footer from '../components/section1-footer';
import Section2 from '../components/section2';
import Section3 from '../components/section3';
import SectionSteps from '../components/SectionSteps';
import Section4 from '../components/section4';
// import Section4lottie from '../components/section4lottie';
import SectionTokenInfo from '../components/sectionTokenInfo'; // Import the new section
import Footer from '../components/footer';
import config from '../config';

function Contact() {
  return (
    <div className="App">
      <Header
        logo={config.images.logoSlogan}
        appSubTitle={config.images.appSubTitle}
      />
      <div className="sub-header"></div>
      <div className="sections-container">
        {config.sections.section1Toggle && (
          <>
            <Section1lottie
              character={config.images.character}
              description={config.images.description}
              button={config.images.button}
              buttonLink={config.links.button}
            />
            <Section1Footer bannerImage={config.images.banner} />
          </>
        )}
        {config.sections.section2Toggle && (
          <Section2
            section2Title={config.images.section2Title}
            section2Content={config.images.section2Content}
          />
        )}
        {config.sections.section3Toggle && (
          <Section3
            section3Title={config.images.section3Title}
            section3Content={config.images.section3Content}
          />
        )}
        {config.sections.sectionStepsToggle && (
          <SectionSteps
            titleImage={config.images.section3_5Title}
            steps={[config.images.step1, config.images.step2, config.images.step3, config.images.step4]}
          />
        )}
         {config.sections.sectionTokenInfoToggle && ( // Add this section
          <SectionTokenInfo
            sectionTokenInfoImage={config.images.sectionTokenInfoImage}
            sectionTokenInfoSupply={config.images.sectionTokenInfoSupply}
            sectionTokenInfoClipboardImage={config.images.sectionTokenInfoClipboardImageTBA}
            sectionTokenInfoTitle={config.images.sectionTokenInfoTitle}
            clipboardText={config.clipboardText} // Add this line
            buttonLink={config.links.button}
          />
        )}
        {config.sections.section4Toggle && (
          <Section4
            section4Title={config.images.section4Title}
            twitter={config.images.twitter}
            telegram={config.images.telegram}
            tiktok={config.images.tiktok}
            instagram={config.images.instagram}
            section4Button={config.images.section4Button}
            section4ContentLeft={config.images.section4ContentLeft}
            buttonLink={config.links.button}
            twitterLink={config.links.twitter}
            telegramLink={config.links.telegram}
            tiktokLink={config.links.tiktok}
            instaLink={config.links.instagram}
            logoSlogan={config.images.logoSlogan}
          />
        )}
       
      </div>
      {config.sections.footerToggle && (
        <Footer />
      )}
    </div>
  );
}

export default Contact;
