import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import WalletTool from './pages/WalletTool';
import BrunosWorkshop from './pages/BrunosWorkshop';
import MemesCreator from './pages/MemesCreator';
import Contact from './pages/Contact';
import NavBar from './components/navBar';
import config from './config';
import './App.css'; // Ensure you have imported any necessary global styles

function App() {
  return (
    <Router>
      <div className="App">
        {config.sections.showNavBar && <NavBar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/WalletTool" element={<WalletTool />} />
          <Route path="/BrunosWorkshop" element={<BrunosWorkshop />} />
          <Route path="/MemesCreator" element={<MemesCreator />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </div>
    </Router> 
  );
}

export default App;
