// src/components/Section1Footer.js
import React from 'react';
import PropTypes from 'prop-types';

function Section1Footer({ bannerImage }) {
  return (
    <div className="section1-footer">
      <div className="banner-wrapper">
        <img src={bannerImage} className="banner-image" alt="banner" />
        <img src={bannerImage} className="banner-image" alt="banner" />
      </div>
    </div>
  );
}

Section1Footer.propTypes = {
  bannerImage: PropTypes.string.isRequired,
};

export default Section1Footer;
