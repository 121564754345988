import React from 'react';

function Section3({ section3Title, section3Content }) {
  return (
    <section className="section3">
      <div className="section3-main">
        <img src={section3Title} className="section3-title" alt="Section 3 Title" />
        <div className="section3-sub-title">
          <img src={section3Content} className="section3-sub-title-content" alt="Section 3 sub title content" />
        </div>
        <div className="section3-paragraph-container">
          <p className="section3-paragraph">
            Placeholder paragraph for section 3.
          </p>
          <p className="section3-paragraph">
            Main idea is ready. You can start working on the top part and will send an updated version by tomorrow for the rest:
            will add detail under tokenomics update slightly the how to buy section I am using font-family: Komika Axis (can you embed it for web, or should I find something else?) Green background color: #26bb26 The running "marquee-running-text" we can do the same like ponke.xyz or roost.wtf We can enable some parallax-effect on bruno, while scrolling down the green dollar can go up, as will be another layer.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Section3;
