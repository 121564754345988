import React from 'react';
import PropTypes from 'prop-types';

function SectionTokenInfo({
  sectionTokenInfoImage,
  sectionTokenInfoSupply,
  sectionTokenInfoClipboardImage,
  sectionTokenInfoTitle,
  clipboardText,
  buttonLink
}) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(clipboardText).then(
      () => {
        alert('Copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  return (
    <section className="sectionTokenInfo">
      <div className="sectionTokenInfo-title">
       
          <img src={sectionTokenInfoTitle}  className="sectionTokenInfo-title-image" alt="Token Title" />
      
      </div>
      <div className="sectionTokenInfo-main">
        <div className="sectionTokenInfo-image-left">
        <a href={buttonLink} target="_blank" rel="noopener noreferrer">
            <img src={sectionTokenInfoImage} alt="Token Info" />
            </a>
      
        </div>
        <div className="sectionTokenInfo-images-right">
          <img src={sectionTokenInfoSupply} className="sectionTokenInfo-image-top" alt="Token Supply" />
          <img
            src={sectionTokenInfoClipboardImage}
            className="sectionTokenInfo-image-bottom"
            alt="Clipboard"
            onClick={copyToClipboard}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </section>
  );
}

SectionTokenInfo.propTypes = {
  sectionTokenInfoImage: PropTypes.string.isRequired,
  sectionTokenInfoSupply: PropTypes.string.isRequired,
  sectionTokenInfoClipboardImage: PropTypes.string.isRequired,
  clipboardText: PropTypes.string.isRequired,
  boardLink: PropTypes.string.isRequired, // Added to PropTypes
  sectionTokenInfoTitle: PropTypes.string.isRequired, // Added to PropTypes
};

export default SectionTokenInfo;
