// src/components/Layers.js
import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


function Layers() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [layerVisible, setLayerVisible] = useState(false);
  const [layerSize, setLayerSize] = useState({ width: 140, height: 100 });
  const [showInstructions, setShowInstructions] = useState(false);
//   const [rotation, setRotation] = useState(0);
  const imageRef = useRef(null);

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setUploadedImage(reader.result);
    if (file) reader.readAsDataURL(file);
  };

  const navigate = useNavigate(); // Create navigate instance
  
  const handleClick = () => {
    navigate('/MemesCreator'); // Navigate to the Services page
  };
  // Handle layer addition
  const handleAddLayer = () => setLayerVisible(true);

  // Handle increasing/decreasing width/height
  const handleIncreaseWidth = () => setLayerSize({ ...layerSize, width: layerSize.width + 20 });
  const handleDecreaseWidth = () => setLayerSize({ ...layerSize, width: Math.max(50, layerSize.width - 20) });
  const handleIncreaseHeight = () => setLayerSize({ ...layerSize, height: layerSize.height + 20 });
  const handleDecreaseHeight = () => setLayerSize({ ...layerSize, height: Math.max(50, layerSize.height - 20) });

  // Handle image rotation
//   const handleRotate = () => setRotation((rotation + 180) % 360);

  // Handle download
  const handleDownload = () => {
    html2canvas(imageRef.current).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'BowStaysOn.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  };

  // Toggle instructions overlay
  const toggleInstructions = () => setShowInstructions(!showInstructions);

  return (
    <div className="layers-page">

      <div style={{ position: 'relative' }}>
        <div className="image-window" ref={imageRef}>
          {uploadedImage && <img src={uploadedImage} alt="Uploaded" style={{ width: '100%', height: '100%' }} />}
          {layerVisible && (
            <Draggable bounds="parent">
              <div
                style={{
                  width: `${layerSize.width}px`,
                  height: `${layerSize.height}px`,
                  position: 'absolute',
                //   transform: `rotate(${rotation}deg)`,
                }}
              >
                <img src="/images/asset1.png" alt="Layer" style={{ width: '100%', height: '100%' }} />
              </div>
            </Draggable>
          )}
        </div>

        <input type="file" onChange={handleImageUpload} style={{ display: 'block', margin: '20px auto' }} />
        <button className="layerButton" onClick={handleAddLayer}>Add Bow</button>
        <button className="layerButton" onClick={handleDownload}>Download</button>
      </div>

      {layerVisible && (
        <div className="control-buttons">
          <div>
            <button onClick={handleIncreaseWidth}>+</button>
            <span>Width</span>
            <button onClick={handleDecreaseWidth}>-</button>
          </div>

          <div>
            <button onClick={handleIncreaseHeight}>+</button>
            <span>Height</span>
            <button onClick={handleDecreaseHeight}>-</button>
          </div>

          {/* <button onClick={handleRotate} className="layerButton">Rotate 180°</button> */}
        </div>
      )}

      <div className="info-icon" title="Click for Instructions" onClick={toggleInstructions}>
      ℹ️
      </div>

      {/* Instructions Overlay */}
      {showInstructions && (
        <div
          className="instructions-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            className="instructions-container"
            style={{
                color: '#333',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '600px',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <h2>How to Use the Layer Component</h2>
            <p>1. Upload an image by clicking the "Choose File" button.</p>
            <p>2. Add a new layer by clicking the "Add Layer" button.</p>
            <p>3. Drag the layer to position it within the uploaded image.</p>
            <p>4. Use the buttons to adjust the width and height of the layer.</p>
            
            <p>5. Download the final image by clicking the "Download" button.</p>
            <button
              className="close-button"
              onClick={toggleInstructions}
              style={{
                position: 'relative',
    
                backgroundColor: '#ff5c5c',
                border: 'none',
                borderRadius: '50%',
                color: '#fff',
                fontSize: '18px',
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
            >
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                X
              </div>
              
            </button>
          </div>
        </div>
      )}
      <button onClick={handleClick}>  
        Create a Meme
      </button>
    </div>
  );
}

export default Layers;
