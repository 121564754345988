import React, { useState } from 'react';
import { Wallet, formatEther, JsonRpcProvider } from 'ethers';
import { FaCopy } from 'react-icons/fa'; // Import the copy icon
import SolanaWalletGenerator from './walletGenSolana';
import { copyToClipboard } from '../utils/utils'; // Import the copy to clipboard function


const WalletGenerator = () => {
  const [wallet, setWallet] = useState(null);
  const [balance, setBalance] = useState(null);
  const [showSeedPhrase, setShowSeedPhrase] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showBackup, setShowBackup] = useState(false);
  const [confirmWords, setConfirmWords] = useState({ word1: '', word2: '', word3: '' });
  const [randomIndexes, setRandomIndexes] = useState([]);
  const [backupConfirmed, setBackupConfirmed] = useState(false);
  const [customStrings, setCustomStrings] = useState({ start: '', contains: '', end: '' });
  const [useCustomString, setUseCustomString] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [isGenerating, setIsGenerating] = useState(false); // Wallet generation in progress

  const startLoading = () => {
    setLoading(true);
    setTimeout(() => {
      generateWallet();
    }, 0); // Start generation immediately after showing overlay
  };

  const generateWallet = async () => {
    setIsGenerating(true);
    let newWallet;
    const { start, contains, end } = customStrings;

    try {
      do {
        newWallet = Wallet.createRandom();
      } while (
        !newWallet.address.slice(2).startsWith(start) ||
        !newWallet.address.includes(contains) ||
        !newWallet.address.endsWith(end)
      );

      setWallet(newWallet);
      setShowSeedPhrase(false);
      setShowPrivateKey(false);
      setShowBackup(false);
      setBackupConfirmed(false);
      setRandomIndexes(generateRandomIndexes(12));

      const provider = new JsonRpcProvider('https://eth-mainnet.g.alchemy.com/v2/HSmMMbfiuiSirc-_frh-DK3QKsmZEsMq');
      const walletBalance = await provider.getBalance(newWallet.address);
      setBalance(formatEther(walletBalance));
    } catch (error) {
      console.error('Error generating wallet:', error);
    } finally {
      setLoading(false);
      setIsGenerating(false);
    }
  };

  const handleSpecificGenerate = () => {
    setWallet(null); // Clear wallet info
    setUseCustomString(false); // Reset the checkbox
    setLoading(false);
    setBackupConfirmed(false);
  };

  const generateRandomIndexes = (length) => {
    let indexes = [];
    while (indexes.length < 3) {
      const index = Math.floor(Math.random() * length);
      if (!indexes.includes(index)) indexes.push(index);
    }
    return indexes.sort((a, b) => a - b);
  };

  const handleRevealSeed = () => {
    setShowSeedPhrase(true);
  };

  const handleRevealPrivateKey = () => {
    setShowPrivateKey(true);
  };

  const handleBackup = () => {
    setShowBackup(true);
    setShowSeedPhrase(false);
    setShowPrivateKey(false);
  };

  const handleWordChange = (e, wordNum) => {
    setConfirmWords({
      ...confirmWords,
      [`word${wordNum}`]: e.target.value,
    });
  };

  const handleConfirmBackup = () => {
    const seedWords = wallet.mnemonic.phrase.split(' ');
    const isCorrect =
      confirmWords.word1 === seedWords[randomIndexes[0]] &&
      confirmWords.word2 === seedWords[randomIndexes[1]] &&
      confirmWords.word3 === seedWords[randomIndexes[2]];

    if (isCorrect) {
      alert('Backup confirmed successfully!');
      setBackupConfirmed(true);
    } else {
      alert('Incorrect words. Please try again.');
    }
  };

 

  return (
    <div className="wallet-generator">
      
      {/* Image Row */}
    <div className="image-row">
      <img src="/images/eth.png" alt="Ethereum" className="crypto-image" />
      <img src="/images/binance.png" alt="Binance" className="crypto-image" />
      <img src="/images/polygon.png" alt="Polygon" className="crypto-image" />
      <img src="/images/cronos.png" alt="Cronos" className="crypto-image" />
      <img src="/images/optimism.png" alt="Optimism" className="crypto-image" />
    </div>
    <h2>EVM Wallet Generator</h2>
      {!isGenerating && !loading && (
        <>
          {!wallet && (
            <>
            <label>
              <input
                type="checkbox"
                checked={useCustomString}
                onChange={() => setUseCustomString(!useCustomString)}
              />{' '}
              Use custom Restrictions for address
              </label>
              {useCustomString && (
                <div>
                  <input
                    type="text"
                    placeholder="Begins with"
                    value={customStrings.start}
                    onChange={(e) => setCustomStrings({ ...customStrings, start: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Contains"
                    value={customStrings.contains}
                    onChange={(e) => setCustomStrings({ ...customStrings, contains: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Ends with"
                    value={customStrings.end}
                    onChange={(e) => setCustomStrings({ ...customStrings, end: e.target.value })}
                  />
                </div>
              )}
            </>
          )}
          {!wallet && (
            <button onClick={startLoading} disabled={loading}>Generate EVM Wallet</button>
          )}
        </>
      )}

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p className="loading-text">
            Generating wallets with specific restrictions can take multiple minutes or even hours to identify the correct wallet. Please be patient while your request is processed.
          </p>
        </div>
      )}

      {wallet && !backupConfirmed && (
        <div className="wallet-info">
          <button onClick={handleSpecificGenerate} className="go-back-button">
            Go Back
          </button>
          <h3>Wallet Information</h3>
          <p><strong>Address:</strong> {wallet.address}</p>
          <button className="copy-button" onClick={() => copyToClipboard(wallet.address)}>
          <FaCopy />Copy
            </button>
          <p><strong>Balance:</strong> {balance ? `${balance} ETH` : 'Fetching balance...'}</p>

          <div className="private-key-container">
            {!showPrivateKey ? (
              <div>
                <p><strong>Private Key:</strong> ************</p>
                <button onClick={handleRevealPrivateKey}>Reveal Private Key</button>
              </div>
            ) : (
              <div>
                <p><strong>Private Key:</strong> {wallet.privateKey}</p>
                <button className="copy-button" onClick={() => copyToClipboard(wallet.privateKey)}>
                  <FaCopy /> Copy
                </button>
              </div>
            )}
          </div>

          <div className="seed-phrase-container">
            <h4>Your Seed Phrase</h4>
            {!showSeedPhrase ? (
              <div className="blurred-seed">
                ************ <br />
                <button onClick={handleRevealSeed}>Reveal Seed Phrase</button>
              </div>
            ) : (
              <div>
                <p>{wallet.mnemonic.phrase}</p>
                <button className="copy-button" onClick={() => copyToClipboard(wallet.mnemonic.phrase)}>
                  <FaCopy /> Copy
                </button>
              </div>
            )}
          </div>

          {showSeedPhrase && !showBackup && (
            <button onClick={handleBackup} style={{ marginTop: '20px' }}>
              Backup Phrase
            </button>
          )}

          {showBackup && (
            <div className="backup-confirmation">
              <h4>Confirm Backup</h4>
              <p>Enter the correct words for positions {randomIndexes[0] + 1}, {randomIndexes[1] + 1}, and {randomIndexes[2] + 1}:</p>

              <div>
                <input
                  type="text"
                  placeholder={`Word ${randomIndexes[0] + 1}`}
                  value={confirmWords.word1}
                  onChange={(e) => handleWordChange(e, 1)}
                />
                <input
                  type="text"
                  placeholder={`Word ${randomIndexes[1] + 1}`}
                  value={confirmWords.word2}
                  onChange={(e) => handleWordChange(e, 2)}
                />
                <input
                  type="text"
                  placeholder={`Word ${randomIndexes[2] + 1}`}
                  value={confirmWords.word3}
                  onChange={(e) => handleWordChange(e, 3)}
                />
              </div>

              <button onClick={handleConfirmBackup}>Confirm Backup</button>
            </div>
          )}
        </div>
      )}

      {backupConfirmed && (
        <div className="backup-confirmed">
          <p>Backup confirmed successfully! You can now generate another wallet.</p>
          <button onClick={generateWallet}>Generate Another Wallet</button>
          <button onClick={handleSpecificGenerate} style={{ marginLeft: '10px' }}>
            Specific Generate
          </button>
        </div>
      )}
      <SolanaWalletGenerator/>
    </div>
  );
};

export default WalletGenerator;
