import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import config from '../config';

function FooterTron() {
  const navigate = useNavigate(); // Create navigate instance

  const handleClick = () => {
    navigate('/BrunosWorkshop'); // Navigate to the Services page
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="placeholder-text"></p>
        <div className='footer-bow-div'>
          <img src={config.images.footerTextTron} className="footer-image" alt="logo" />
          
          {/* Make the footer-bow-image clickable */}
          <img 
            src={config.images.footerBowTron} 
            className="footer-bow-image" 
            alt="Bow" 
            onClick={handleClick} // Add onClick to handle navigation
            style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickable
          />
        </div>
        
        <a href={config.links.button} target="_blank" rel="noopener noreferrer">
          <img src={config.images.dextools} className="footer-image-2" alt="button" />
        </a>
        <div> brunotronchain@gmail.com </div>
      </div>
    </footer>
  );
}

export default FooterTron;
