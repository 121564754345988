import React from 'react';
import WalletGenerator from '../components/walletGenerator'; // Import the wallet generator component
// import WalletGenBTC from '../components/walletGenBTC';

const WalletTool = () => {
  return (
    <div>
      <h1 style={{color: '#000000', font: 'bold'}}>- Wallet Tools -</h1>
      <WalletGenerator /> {/* Display the wallet generator */}
      {/* <WalletGenBTC/> */}
      <div style={{height: "80px"}}> </div>
    </div>
  );
};

export default WalletTool;
