import React, { useState } from 'react';
import { Keypair } from '@solana/web3.js';
import * as bip39 from 'bip39';
import { Buffer } from 'buffer';
import '../styles/walletGenSolana.css'; // Ensure the correct path
import { copyToClipboard } from '../utils/utils'; // Import the copy to clipboard function
import { FaCopy } from 'react-icons/fa'; // Import the copy icon

// Set the global Buffer object to use the polyfilled Buffer
window.Buffer = Buffer;

const SolanaWalletGenerator = () => {
  const [keypair, setKeypair] = useState(null);
  const [seedPhrase, setSeedPhrase] = useState('');
  const [vanityPrefix, setVanityPrefix] = useState('');
  const [vanityEndsWith, setVanityEndsWith] = useState('');
  const [showKeypair, setShowKeypair] = useState(false);
  const [showSeedPhrase, setShowSeedPhrase] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [backupConfirmed, setBackupConfirmed] = useState(false);
  const [showBackup, setShowBackup] = useState(false);
  const [showVanityInputs, setShowVanityInputs] = useState(true); // State for vanity inputs visibility
  const [hideVanityCheckbox, setHideVanityCheckbox] = useState(false); // Checkbox to hide/show vanity inputs

  // State for backup confirmation words
  const [confirmWords, setConfirmWords] = useState({
    word1: '',
    word2: '',
    word3: ''
  });
  const [randomIndexes, setRandomIndexes] = useState([]);

  const startLoading = () => {
    setBackupConfirmed(false);
    setShowBackup(false);
    setLoading(true);
    setShowVanityInputs(false); // Hide vanity inputs and checkbox
    setTimeout(() => {
      generateWallet();
    }, 0); // Start generation immediately after showing overlay
  };

  const handleSpecificGenerate = () => {
    setLoading(false);
    setBackupConfirmed(false);
    setShowBackup(false);
    setShowVanityInputs(true);
  };

  const generateWallet = () => {
    setLoading(true);
    let kp = Keypair.generate();

    if (vanityPrefix || vanityEndsWith) {
      while (
        (vanityPrefix && !kp.publicKey.toBase58().startsWith(vanityPrefix)) ||
        (vanityEndsWith && !kp.publicKey.toBase58().endsWith(vanityEndsWith))
      ) {
        kp = Keypair.generate();
      }
    }

    const mnemonic = bip39.generateMnemonic();
    const seed = bip39.mnemonicToSeedSync(mnemonic, '');
    const restoredKeypair = Keypair.fromSeed(seed.slice(0, 32));

    setKeypair(kp);
    setSeedPhrase(mnemonic);
    setShowKeypair(true);
    setShowPrivateKey(false);
    setShowSeedPhrase(false); // Ensure seed phrase is shown
    setLoading(false);
    setRandomIndexes(generateRandomIndexes(12));

    // Generate random indexes for backup confirmation
    const words = mnemonic.split(' ');
    const indexes = [];
    while (indexes.length < 3) {
      const rand = Math.floor(Math.random() * words.length);
      if (!indexes.includes(rand)) indexes.push(rand);
    }
    setRandomIndexes(indexes);
  };

  const handleBackup = () => {
    setShowBackup(true);
  };

  const handleWordChange = (e, wordIndex) => {
    setConfirmWords((prev) => ({
      ...prev,
      [`word${wordIndex}`]: e.target.value
    }));
  };

  const generateRandomIndexes = (length) => {
    let indexes = [];
    while (indexes.length < 3) {
      const index = Math.floor(Math.random() * length);
      if (!indexes.includes(index)) indexes.push(index);
    }
    return indexes.sort((a, b) => a - b);
  };

  const handleConfirmBackup = () => {
    const words = seedPhrase.split(' ');
    const correctWords = randomIndexes.map((index) => words[index]);
    if (
      confirmWords.word1 === correctWords[0] &&
      confirmWords.word2 === correctWords[1] &&
      confirmWords.word3 === correctWords[2]
    ) {
      alert('Backup confirmed successfully!');
      setBackupConfirmed(true);
    } else {
      alert('Backup confirmation failed. Please check your words and try again.');
    }
  };

  const handleGoBack = () => {
    setShowKeypair(false);
    setBackupConfirmed(false);
    setShowVanityInputs(true); // Show vanity inputs and checkbox
  };

  return (
    <div className="solana-wallet-generator">
      
  
      <div className="image-row-sol">
      <img src="/images/sol.png" alt="Solana" className="crypto-image-sol" />
 
    </div>
    <h2>Solana Wallet Generator</h2>

      <label>
        <input
          type="checkbox"
          checked={hideVanityCheckbox}
          onChange={() => setHideVanityCheckbox(!hideVanityCheckbox)}
        />
        Show Vanity Wallet Options
      </label>
  
      {hideVanityCheckbox && showVanityInputs && (
        <>
          <input
            type="text"
            placeholder="Starts with (optional)"
            value={vanityPrefix}
            onChange={(e) => setVanityPrefix(e.target.value)}
          />
          <input
            type="text"
            placeholder="Ends With (optional)"
            value={vanityEndsWith}
            onChange={(e) => setVanityEndsWith(e.target.value)}
          />
        </>
      )}
  
      {showVanityInputs && (
        <button onClick={startLoading} disabled={loading}>
          {loading ? 'Generating...' : 'Generate Solana Wallet'}
        </button>
      )}
  
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p className="loading-text">
            Generating wallets with specific restrictions can take some time. Please be patient while we process your request.
          </p>
        </div>
      )}
  
      {showKeypair && !backupConfirmed && (
        <div className="keypair-info">
          <div>
            <button onClick={handleGoBack} className="go-back-button">
              Go Back
            </button>
            <strong>Public Address:</strong>
            <span>{keypair.publicKey.toBase58()}</span>
            <button
              className="copy-button"
              onClick={() => copyToClipboard(keypair.publicKey.toBase58())}
            >
             <FaCopy /> Copy
            </button>
          </div>
          <div>
            <strong>Key Pair:</strong>
            <span>{showKeypair ? keypair.secretKey.toString() : '********'}</span>
            <button
              className="copy-button"
              onClick={() => copyToClipboard(keypair.secretKey.toString())}
            >
              <FaCopy />Copy
            </button>
          </div>
          <div>
            <strong>Private Key:</strong>
            <span>{showPrivateKey ? Buffer.from(keypair.secretKey).toString('base64') : '********'}</span>
            <button
              className="reveal-button"
              onClick={() => setShowPrivateKey(!showPrivateKey)}
            >
              {showPrivateKey ? 'Hide Private Key' : 'Reveal Private Key'}
            </button>
            {showPrivateKey && (
              <button
                className="copy-button"
                onClick={() => copyToClipboard(keypair.secretKey.toString())}
              >
              <FaCopy />  Copy
              </button>
            )}
          </div>
          <div>
            <strong>Seed Phrase:</strong>
            <span>
              {showSeedPhrase
                ? seedPhrase
                : '***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** *****'}
            </span>
            <button
              className="reveal-button"
              onClick={() => setShowSeedPhrase(!showSeedPhrase)}
            >
              {showSeedPhrase ? 'Hide Seed Phrase' : 'Reveal Seed Phrase'}
            </button>
            {showSeedPhrase && (
              <button
                className="copy-button"
                onClick={() => copyToClipboard(seedPhrase)}
              >
                Copy
              </button>
            )}
          </div>
          {showSeedPhrase && !showBackup && (
            <button className="backup-button" onClick={handleBackup}>
              Backup Seed Phrase
            </button>
          )}
      
      {showBackup && (
        <div className="backup-confirmation">
          <h4>Confirm Backup</h4>
          <p>
            Enter the correct words for positions {randomIndexes[0] + 1},{' '}
            {randomIndexes[1] + 1}, and {randomIndexes[2] + 1}:
          </p>
          <div>
            <input
              type="text"
              placeholder={`Word ${randomIndexes[0] + 1}`}
              value={confirmWords.word1}
              onChange={(e) => handleWordChange(e, 1)}
            />
            <input
              type="text"
              placeholder={`Word ${randomIndexes[1] + 1}`}
              value={confirmWords.word2}
              onChange={(e) => handleWordChange(e, 2)}
            />
            <input
              type="text"
              placeholder={`Word ${randomIndexes[2] + 1}`}
              value={confirmWords.word3}
              onChange={(e) => handleWordChange(e, 3)}
            />
          </div>
          <button className="confirm-button" onClick={handleConfirmBackup}>
            Confirm Backup
          </button>
        </div>
      )}
        </div>
      )}
  
  
      {backupConfirmed && (
        <div className="backup-confirmation-success">
          <p>Backup successfully confirmed!</p>
          <button onClick={handleGoBack}>Generate Another Wallet</button>
        </div>
      )}
    </div>
  );
  };
  
  export default SolanaWalletGenerator;
  