import React from 'react';

function Section4({ section4Title, twitter, telegram, tiktok, instagram, section4Button, section4ContentLeft, buttonLink, twitterLink, telegramLink, tiktokLink, instaLink, logoSlogan }) {
  return (
    <section className="section4">
      <div className="section4-main">
        <img src={section4Title} className="section4-title" alt="Section 4 Title" />
        <div className="social-media-icons">
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={twitter} className="social-icon" alt="Twitter" />
          </a>
          <a href={telegramLink} target="_blank" rel="noopener noreferrer">
            <img src={telegram} className="social-icon" alt="Telegram" />
          </a>
          <a href={tiktokLink} target="_blank" rel="noopener noreferrer">
            <img src={tiktok} className="social-icon" alt="TikTok" />
          </a>
          <a href={instaLink} target="_blank" rel="noopener noreferrer">
            <img src={instagram} className="social-icon" alt="Instagram" />
          </a>
        </div>
        <div className="section4-content-container">
          <div className="section4-content-right">
            <a href={buttonLink} target="_blank" rel="noopener noreferrer">
              <img src={section4Button} className="section4-button" alt="section4 button" />
              </a>
              <a href={buttonLink} target="_blank" rel="noopener noreferrer">
              <img src={logoSlogan} className="section4-underbutton" alt="section4 underbutton" />
            </a>
          </div>
          <div className="section4-content-left">
            <img src={section4ContentLeft} className="section4-content-left-image" alt="Section 4 Content Left" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
