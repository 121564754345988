import React, { useState, useEffect } from 'react';

function Header({ logo, appSubTitle }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const subtitleStyle = {
    width: '200px',
    height: 'auto',
    paddingTop: '25px',
    position: 'absolute',
    top: isMobile ? '50%' : '50%', // 30% for mobile, 50% for desktop
    left: isMobile ? '50%' : '50%',  // 3% for mobile, 50% for desktop
    transform: isMobile ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)', //  translate(-50%, -50%) Remove centering for mobile
    zIndex: '-2',
  };

  return (
    <header className="App-header" style={{ zIndex: '-9' }}>
      <div className="logo-container">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div style={{ height: '100px', position: 'relative' }}>
        <img 
          src={appSubTitle} 
          className="appSubTitle" 
          alt="sub" 
          style={subtitleStyle} 
        />
      </div>
    </header>
  );
}

export default Header;
