// src/components/Section3_5.js
import React from 'react';


const SectionSteps = ({ titleImage, steps }) => {
  return (
    <section className="section3_5">
      <div className="section3_5-content">
        <img src={titleImage} alt="Title" className="section3_5-title" />
        
        <div className="section3_5-steps-container">
          <div className="section3_5-steps">
            {steps.map((step, index) => (
              <div key={index} className="section3_5-step-wrapper">
                <img src={step} alt={`Step ${index + 1}`} className="section3_5-step" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSteps;
