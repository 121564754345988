const config = {
  
  clipboardText: 'TDnqWcESMva5Mftn912eFX64iv6JKNFpCH', // Add this line
    
    images: {
      logo: '/images/logo.png',
      appSubTitle: '/images/tronImages/brunoChinese4_white.png',
      character: '/images/character.gif',
      description: '/images/description.png',
      descriptionTron: '/images/tronImages/description.png',
      button: '/images/button.png',
      buttonTron: '/images/tronImages/button.png',
      banner: '/images/brunoTape.png',
      section2Title: '/images/tronImages/section2title.png',
      section2Content: '/images/section2content.png',
      section3Title: '/images/section3title.png',
      section3Content: '/images/section3content.png',
      section3_5Title: '/images/section3_5title.png',
      section3_5TitleTron: '/images/tronImages/section3_5title.png',
      section3_5Content: '/images/section3content.png',
      sectionTokenInfoImage: '/images/sectionTokenInfoImage.gif',
      sectionTokenInfoImageTron: '/images/tronImages/sectionTokenInfoImage.png',
      sectionTokenInfoClipboardImage: '/images/sectionTokenInfoClipboardImage.png',
      sectionTokenInfoClipboardImageTBA: '/images/sectionTokenInfoClipboardImageTBA.png',
      sectionTokenInfoSupply: '/images/sectionTokenInfoSupply.png',
      sectionTokenInfoTitle: '/images/section3title.png',
      sectionTokenInfoTitleTron: '/images/tronImages/section3title.png',
      section4Title: '/images/section4title.png',
      twitter: '/images/twitter.png',
      telegram: '/images/telegram.png',
      tiktok: '/images/tiktok.png',
      instagram: '/images/instagram.png',
      section4Button: '/images/section4button.png',
      section4ButtonTron: '/images/tronImages/section4button.png',
      section4ContentLeft: '/images/section4contentleft.gif',
      step1: '/images/step1.png',
      step2: '/images/step2.png',
      step3: '/images/step3.png',
      step4: '/images/step4.png',
      step1tron: '/images/tronImages/step1.png',
      step2tron: '/images/tronImages/step2.png',
      step3tron: '/images/tronImages/step3.png',
      step4tron: '/images/tronImages/step4.png',
    logoSlogan: '/images/logoSlogan.png',
    footerText: '/images/footerText.png',
    logoSloganTron: '/images/tronImages/logoSlogan.png',
    footerTextTron: '/images/tronImages/footerText.png',
    footerBowTron: '/images/putOnABow.png',
    dextools: '/images/dextools.png',
    },
    links: {
      button: 'https://www.dextools.io/app/en/tron/pair-explorer/TQENocBdhBr11g7zFWQ74RagoRC5G72m76?t=1724219020911',
      buttonTron: 'https://sunpump.meme/token/TDnqWcESMva5Mftn912eFX64iv6JKNFpCH',
      twitter: 'https://x.com/Brunotroncoin',
      telegram: 'https://t.me/brunotronchain',
      tiktok: 'https://www.tiktok.com/@brunotroncoin',
      instagram: 'https://www.instagram.com/brunotronchain',
    },
    sections: {
        section1Toggle: true,
        section2Toggle: false,
        section3Toggle: false,
        sectionStepsToggle: true,
        sectionTokenInfoToggle: true,
        section4Toggle: true,
        footerToggle: true,
        showNavBar: false,
      },
    colors: {
        primary: '#4aa53f',
        oldPrimary: '#26bb26',
        secondary: '#39b639',
        background: '#141414',
        text: '#ffffff',
        footerBackground: '#000000',
        bannerColor: '#e0e0e0',
      },
  };
  
  export default config;
  