
import '@dotlottie/player-component'; // Import the Lottie player
import config from '../config'; // Ensure this path is correct
import React, { useState, useEffect } from 'react';

const Section1lottie = ( 
  character,
  description,
  button,
  buttonLink) => {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Preload animation
    const preloadAnimation = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/animations/animation1.json`);
        if (response.ok) {
          setIsLoaded(true);
        } else {
          console.error('Failed to preload animation');
        }
      } catch (error) {
        console.error('Error preloading animation:', error);
      }
    };

    preloadAnimation();
  }, []);

  return (
    <section className="section1">
      <div className="section1-main">
        <div className="section1-main-content-left-lottie">
        {isLoaded ? (
          <dotlottie-player
            src={`${process.env.PUBLIC_URL}/animations/animation1.json`} // Use the correct path for the hosted animation
            background="transparent"
            speed="1"
            style={{ width: 'auto', height: 'auto' }} // Set specific dimensions to test visibility
            direction="1"
            playMode="normal"
            loop
            autoplay
          />
        ) : (
          <div className="animation-placeholder">Loading...</div> // Placeholder while loading
        )}
            {/* <dotlottie-player
            src="https://lottie.host/17ffb713-f685-4f02-b156-43fb901c3c28/3W5mJyXhaJ.json"
            background="transparent"
            speed="1"
            style={{ width: 'auto', height: 'auto' }}
            direction="1"
            playMode="normal"
            loop
            autoplay
          /> */}
        </div>
        <div className="section1-main-description-right-lottie">
          <img src={config.images.descriptionTron} className="description" alt="Description" />
          <a href={config.links.buttonTron} target="_blank" rel="noopener noreferrer">
            <img src={config.images.buttonTron} className="button" alt="Button" style={{paddingBottom: '90px'}} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section1lottie;
